import React from 'react';
import logo from './logo.svg';
import './App.css';
import { DishList } from './components/unsavedDish';
import { DishesCalendar } from './components/dishesCalendar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "@mantine/core/styles.css";
import { Button, MantineProvider } from "@mantine/core";
import 'react-calendar/dist/Calendar.css';
function App() {

  // const triggerNotification = () => {
  //   showNotification({
  //     title: 'Уведомление',
  //     message: 'Это пример уведомления!',
  //     color: 'blue',
  //   });
  // };

  return (
    // <MantineProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/dishes/unsaved/:dishesUUID" element={
            <div className="App" style={{ height: '100%' }}>
              <MantineProvider>
                <DishList />
              </MantineProvider>

            </div>
            } />
            {/* <Notifications position="top-right" zIndex={1000} limit={1}/> */}
          <Route path="/calendar" element={
            <div className="App" style={{ height: '100%' }}>
              <MantineProvider>
                <DishesCalendar />
              </MantineProvider>
          </div>
            } />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
