import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; 
import './CustomCalendar.css'; // файл с кастомными стилями
import { Tabs, Paper, Grid, TextInput, ActionIcon, Text, Button, Collapse } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import Loader from 'react-ts-loaders'

interface Dish {
  id: string;
  date: string;
  mealType: string;
  name: string;
  weight: number;
  protein: number;
  fat: number;
  carbs: number;
  fiber: number;
  calories: number;
}

interface DayData {
  total_calories: number;
  dishes: Dish[];
}

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы идут с 0, поэтому добавляем +1
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

let mealTypeDishesDict: any = {
  'breakfast': 'завтрак',
  'lunch': 'обед',
  'dinner': 'ужин',
  'fast_meal': 'перекус',
}

export const DishesCalendar: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [dataByDate, setDataByDate] = useState<{ [date: string]: DayData }>({});
  const [selectedMealType, setSelectedMealType] = useState<string>('breakfast');
  const [opened, setOpened] = useState<number | null>(null);
  const [isDishesListLoading, setIsDishesListLoading] = useState<boolean>(false);
  const [totalCaloriesPerDay, setTotalCaloriesPerDay]  = useState<{ [key: string]: number }>({});
  const [fromTargetCaloriesPerDay, setFromTargetCaloriesPerDay] = useState<number | undefined>(undefined);
  const [tillTargetCaloriesPerDay, setTillTargetCaloriesPerDay] = useState<number | undefined>(undefined);
  
  const updateDishesForDate = (date: string, dishes: Dish[]) => {
    const totalCalories = dishes.reduce((acc, dish) => acc + dish.calories, 0);

    setDataByDate((prevData) => ({
      ...prevData,
      [date]: {
        total_calories: totalCalories,
        dishes,
      },
    }));
  };

  const handleDateChange = async (date: Date) => {
    setSelectedDate(date);
    await fetchAndSetDishes(date);
  };

  const getCaloriesCountForDate = (date: Date): number | undefined => {
    const formattedDate = formatDate(date) // формат YYYY-MM-DD
    if (totalCaloriesPerDay[formattedDate]) {
      return totalCaloriesPerDay[formattedDate] || 0
    }
    return undefined
  };

  const getCaloriesColorClass = (count: number | undefined) => {
    if (count === undefined || !fromTargetCaloriesPerDay|| !tillTargetCaloriesPerDay) return 'event-count-gray';
    if (count >= fromTargetCaloriesPerDay && count <= tillTargetCaloriesPerDay) return 'event-count-green';
    return 'event-count-red';
  };

  const fetchAndSetDishes = async (date: Date) => {
    setIsDishesListLoading(true);
    await _fetchAndSetDishes(date);
    setIsDishesListLoading(false);
  }

  const _fetchAndSetDishes = async (date: Date) => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'init-data': Telegram.WebApp.initData || "query_id=AAHPoHVAAAAAAM-gdUDlIrV5&user=%7B%22id%22%3A1081450703%2C%22first_name%22%3A%22%D0%98%D0%B3%D0%BE%D1%80%D1%8C%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22whiteMagic01%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%7D&auth_date=1666804854&hash=77576e1b0b3546836793bf0fee5e564f6614ca1ea639bd9b89149ffe1850cc09"
        },
      };

      const response = await fetch(process.env.REACT_APP_SERVER_URL + `/api/meals/?date=${formatDate(date)}`, requestOptions);
      
      if (!response.ok) {
        throw new Error("Ошибка при загрузке данных");
      }
  
      const data: Dish[] = await response.json();
      updateDishesForDate(data[0].date, data);
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };

  interface NutritionSummary {
    total_calories_per_day: { [key: string]: number };
    from_target_calories_per_day: number | undefined;
    till_target_calories_per_day: number | undefined;
  }
  
  const fetchNutritionSummaryAndSet = async (date: string) => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'init-data': Telegram.WebApp.initData || "query_id=AAHPoHVAAAAAAM-gdUDlIrV5&user=%7B%22id%22%3A1081450703%2C%22first_name%22%3A%22%D0%98%D0%B3%D0%BE%D1%80%D1%8C%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22whiteMagic01%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%7D&auth_date=1666804854&hash=77576e1b0b3546836793bf0fee5e564f6614ca1ea639bd9b89149ffe1850cc09"
        },
      };

      const response = await fetch(process.env.REACT_APP_SERVER_URL + `/api/nutrition_summary?request_date=${date}`, requestOptions);
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const data: NutritionSummary = await response.json();
      setTotalCaloriesPerDay(data.total_calories_per_day)
      setFromTargetCaloriesPerDay(data.from_target_calories_per_day)
      setTillTargetCaloriesPerDay(data.till_target_calories_per_day)
    } catch (error) {
      console.error('Error fetching nutrition summary:', error);
    }
  }
  

  useEffect(() => {
    fetchAndSetDishes(selectedDate);
    fetchNutritionSummaryAndSet(formatDate(selectedDate));
  }, [])


  let dishesData = null

  if (dataByDate[formatDate(selectedDate)] !== undefined) {
    if (dataByDate[formatDate(selectedDate)].dishes.length !== 0) {
      dishesData = dataByDate[formatDate(selectedDate)].dishes.filter((d) => d.mealType === selectedMealType)
    }
  }

  return (
    <div>
      <Calendar
        onClickDay={handleDateChange}
        value={selectedDate}
        tileContent={({ date }) => {
          const count = getCaloriesCountForDate(date);
          if (count) {
            return <div className={`event-count ${date.getTime() === selectedDate.getTime() ? 'event-count-white' : getCaloriesColorClass(count)}`}>
              <b>{count}</b>
            </div>
          }
          return null
        }}
        tileClassName="custom-tile"
      />
      {selectedDate && (
        <div>
        <Text style={{marginLeft: '5px', marginTop: '5px'}} ta="left" size="sm" c="dimmed"  fs="italic">Всего: калории - <b>{getCaloriesCountForDate(selectedDate)}</b>{!fromTargetCaloriesPerDay && ";добавьте данные о целях по калориям для учета"}</Text>
        <Tabs defaultValue="breakfast" style={{ marginTop: '5px' }} onChange={(val) => setSelectedMealType(val!) }>
          <Tabs.List>
            <Tabs.Tab value="breakfast">🍳Завтрак</Tabs.Tab>
            <Tabs.Tab value="lunch">🍲Обед</Tabs.Tab>
            <Tabs.Tab value="dinner">🍽Ужин</Tabs.Tab>
            <Tabs.Tab value="fast_meal">🍏Перекус</Tabs.Tab>
          </Tabs.List>
        </Tabs>
        {isDishesListLoading && <Loader
          type="dualring"
          color="#0a74da"
          size={150}
          message='Ищем данные за сутки!'
          />}

        {dishesData !== null && dishesData.length !== 0 && !isDishesListLoading ? dishesData.map((dish, index) => <Paper key={index} shadow="sm" p="md" mb="sm" withBorder>
          <Grid gutter="xs" >
            <Grid.Col span={6} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end'  }}>
            <b><TextInput
              variant="unstyled"
              value={dish.name}
              readOnly              // onChange={(e) => handleInputChange(index, 'name', e.target.value)}
              classNames={{ input: 'underline-input' }}
            /></b>
                        {/* <b><Text
            size='sm'
              // variant="unstyled"
              // value={dish.name}
              // readOnly              // onChange={(e) => handleInputChange(index, 'name', e.target.value)}
              // // classNames={{ input: 'underline-input' }}
            >{dish.name}</Text></b> */}
            </Grid.Col>
            <Grid.Col span={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end'  }}>
            <TextInput
              readOnly
              variant="unstyled"
              value={dish.weight || undefined}
              type="number"
              // onChange={(e) => handleWeightChange(dish.id, +e.target.value)}
              classNames={{ input: 'underline-input' }}
              rightSection={<span>г</span>}
              rightSectionWidth={-35}
              style={{ width: '30px' }}
            />
            </Grid.Col>
            <Grid.Col span={1} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end'  }}>
              <ActionIcon onClick={() => setOpened(opened === index ? null : index)}>
                {opened === index ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
              </ActionIcon>
            </Grid.Col>
          </Grid>
          <Collapse in={opened === index} mt="sm">
            <Grid gutter="xs">
              <Grid.Col span={4} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }}>
                <Text>Калории:</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  variant="unstyled"
                  value={dish.calories || undefined}
                  type="number"
                  classNames={{ input: 'underline-input' }}
                  style={{ width: '35px' }}
                  readOnly 
                />
              </Grid.Col>
              <Grid.Col span={4} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end'  }}>
                <Text>БЖУ (г):</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Grid gutter="xs">
                  <TextInput
                    variant="unstyled"
                    value={dish.protein}
                    type="number"
                    classNames={{ input: 'underline-input' }}
                    style={{ width: '35px', marginRight: '10px' }}
                    readOnly 
                  />
                  <TextInput
                    variant="unstyled"
                    value={dish.fat}
                    type="number"
                    classNames={{ input: 'underline-input' }}
                    style={{ width: '35px', marginRight: '10px' }}
                    readOnly 
                  />
                  <TextInput
                    variant="unstyled"
                    value={dish.carbs}
                    type="number"
                    classNames={{ input: 'underline-input' }}
                    style={{ width: '35px', marginRight: '10px' }}
                    readOnly 
                  />
                </Grid>
              </Grid.Col>
              <Grid.Col span={4} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }}>
                <Text>Клетчатка (г):</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  variant="unstyled"
                  value={dish.fiber}
                  type="number"
                  classNames={{ input: 'underline-input' }}
                  style={{ width: '35px' }}
                  readOnly 
                />
              </Grid.Col>
            </Grid>
          </Collapse>
        </Paper>) : <Text>Нет записей про {mealTypeDishesDict[selectedMealType]}</Text>}
        </div>
      )}
    </div>
  );
};

export default DishesCalendar;
