import React, { useEffect, useState } from 'react';
import { TextInput, Collapse, ActionIcon, Group, Paper, Grid, Text, Button, Modal, SegmentedControl, Box, stylesToString } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';

interface Dish {
  id: string;
  name: string;
  weight: number;
  protein: number;
  fat: number;
  carbs: number;
  fiber: number;
  calories: number;
  ratios?: {
    proteinRatio: number;
    fatRatio: number;
    carbsRatio: number;
    fiberRatio: number;
    caloriesRatio: number;
  };
}

interface DishesResponse {
  id: string;
  name: string;
  weight: number;
  total_nutrition: DishNutritionResponse;
}

interface DishNutritionResponse {
  total_protein: number;
  total_fats: number;
  total_carbohydrates: number;
  total_fiber: number;
  total_calories: number;
}

interface DishesNutritionTotalResult {
  total_protein: number;
  total_fats: number;
  total_carbohydrates: number;
  total_fiber: number;
  total_calories: number;
}

function calcTotals(dishes: Dish[]): DishesNutritionTotalResult {
  let result = {
    total_protein: 0,
    total_fats: 0,
    total_carbohydrates: 0,
    total_fiber: 0,
    total_calories: 0,
  }
  dishes.forEach((d) => {
    result.total_carbohydrates += d.carbs
    result.total_fats += d.fat
    result.total_protein += d.protein
    result.total_fiber += d.fiber
    result.total_calories += d.calories
  })
  return result
}

interface TotalNutrition {
  total_calories: number;
  total_protein: number;
  total_fats: number;
  total_carbohydrates: number;
  total_fiber: number;
}

interface CreateDish {
  id: string;
  name: string;
  weight: number;
  total_nutrition: TotalNutrition;
}

interface CreateDishesSchema {
  dishes: CreateDish[];
  meal_type: string; // MealType can be replaced with a specific enum or type if necessary
}

function parseDishes(data: DishesResponse[]): Dish[] {
  return data.map(dish => {
    
    let newDish: Dish = {
      id: dish.id,
      name: dish.name,
      weight: dish.weight,
      protein: dish.total_nutrition.total_protein,
      fat: dish.total_nutrition.total_fats,
      carbs: dish.total_nutrition.total_carbohydrates,
      fiber: dish.total_nutrition.total_fiber,
      calories: dish.total_nutrition.total_calories,
    }
    newDish.ratios = initializeRatios(newDish)
    return newDish
  });
}

function initializeRatios(dish: Dish) {
  return {
    proteinRatio: dish.protein / dish.weight,
    fatRatio: dish.fat / dish.weight,
    carbsRatio: dish.carbs / dish.weight,
    fiberRatio: dish.fiber / dish.weight,
    caloriesRatio: dish.calories / dish.weight,
  };
}

// Функция для преобразования Dish в CreateDish
function convertDishToCreateDish(dish: Dish): CreateDish {
  return {
    id: dish.id,
    name: dish.name,
    weight: dish.weight,
    total_nutrition: {
      total_calories: dish.calories,
      total_protein: dish.protein,
      total_fats: dish.fat,
      total_carbohydrates: dish.carbs,
      total_fiber: dish.fiber,
    },
  };
}

// Функция для преобразования списка блюд в CreateDishesSchema
function convertToCreateDishesSchema(dishes: Dish[], mealType: string): CreateDishesSchema {
  const createDishes: CreateDish[] = dishes.map((dish) => convertDishToCreateDish(dish));

  return {
    dishes: createDishes,
    meal_type: mealType,
  };
}

export const DishList: React.FC = () => {
  const { dishesUUID } = useParams<{ dishesUUID: string }>();
  const [dishes, setDishes] = useState<Dish[]>([]);
  const [opened, setOpened] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mealType, setMealType] = useState('breakfast'); // Default value for meal type
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false); // Default value for meal type


  const fetchDishesData = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'init-data': Telegram.WebApp.initData || "query_id=AAHPoHVAAAAAAM-gdUDlIrV5&user=%7B%22id%22%3A1081450703%2C%22first_name%22%3A%22%D0%98%D0%B3%D0%BE%D1%80%D1%8C%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22whiteMagic01%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%7D&auth_date=1666804854&hash=77576e1b0b3546836793bf0fee5e564f6614ca1ea639bd9b89149ffe1850cc09"
      },
    };
    const resp = await fetch(process.env.REACT_APP_SERVER_URL + '/api/dish/unsaved/' + dishesUUID, requestOptions);
    let j = await resp.json()
    console.log(j);
    setDishes(parseDishes(j));
  }

  const saveDishesData = async (mealType: string) => {
    setIsSaveBtnDisabled(true);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'init-data': Telegram.WebApp.initData || "query_id=AAHPoHVAAAAAAM-gdUDlIrV5&user=%7B%22id%22%3A1081450703%2C%22first_name%22%3A%22%D0%98%D0%B3%D0%BE%D1%80%D1%8C%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22whiteMagic01%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%7D&auth_date=1666804854&hash=77576e1b0b3546836793bf0fee5e564f6614ca1ea639bd9b89149ffe1850cc09"
      },
      body: JSON.stringify(convertToCreateDishesSchema(dishes, mealType)),
    };
    const resp = await fetch(process.env.REACT_APP_SERVER_URL + '/api/dish/', requestOptions);
    if (resp.ok) {
      Telegram.WebApp.close()
      return
    }
    setIsSaveBtnDisabled(false);
  }

  useEffect(()=> {
    fetchDishesData();
  }, [])


  const handleInputChange = (index: number, field: keyof Dish, value: string | number) => {
    const updatedDishes = [...dishes];
    updatedDishes[index] = {
      ...updatedDishes[index],
      [field]: typeof value === 'number' ?  Math.round(+value) : value,
    };
    setDishes(updatedDishes);
  };

  function deleteDish(dishId: string) {
    setDishes(dishes.filter((dish) => dish.id !== dishId))
  }

  function handleWeightChange(dishId: string, newWeight: number) {
    setDishes((prevDishes) =>
      prevDishes.map((dish) => {
        if (dish.id === dishId) {
          // console.log(newWeight)
          if (!dish.ratios || newWeight === 0) {
            return {
              ...dish,
              weight: newWeight,
            };
          }
          // Пересчитываем БЖУ и волокна в зависимости от нового веса с учётом исходных коэффициентов
          const updatedDish = {
            ...dish,
            weight: Math.round(Math.round(newWeight * 10) / 10),
            protein: Math.round(Math.round(dish.ratios.proteinRatio * newWeight * 10) / 10),
            fat: Math.round(Math.round(dish.ratios.fatRatio * newWeight * 10) / 10),
            carbs: Math.round(Math.round(dish.ratios.carbsRatio * newWeight * 10) / 10),
            fiber: Math.round(Math.round(dish.ratios.fiberRatio * newWeight * 10) / 10),
            calories: Math.round(Math.round(dish.ratios.caloriesRatio * newWeight * 10) / 10),
          };
  
          return updatedDish;
        }
        return dish;
      })
    );
  }

  const addNewDish = () => {
    setDishes([
      ...dishes,
      { id: uuidv4(), name: '', weight: 0, protein: 0, fat: 0, carbs: 0, fiber: 0, calories: 0 },
    ]);
  };

  let totals = calcTotals(dishes)
  return (
    <div>
      {dishes.map((dish, index) => (
        <Paper key={index} shadow="sm" p="md" mb="sm" withBorder>
          <Grid gutter="xs" >
            <Grid.Col span={6} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end'  }}>
            <b><TextInput
              variant="unstyled"
              value={dish.name}
              onChange={(e) => handleInputChange(index, 'name', e.target.value)}
              classNames={{ input: 'underline-input' }}
            /></b>
            </Grid.Col>
            <Grid.Col span={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end'  }}>
            <TextInput
              variant="unstyled"
              value={dish.weight || undefined}
              type="number"
              onChange={(e) => handleWeightChange(dish.id, +e.target.value)}
              classNames={{ input: 'underline-input' }}
              rightSection={<span>г</span>}
              rightSectionWidth={-35}
              style={{ width: '30px' }}
            />
            </Grid.Col>
            <Grid.Col span={1} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end'  }}>
            <ActionIcon onClick={() => setOpened(opened === index ? null : index)}>
              {opened === index ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
            </ActionIcon>
            </Grid.Col>
          </Grid>

          {/* Дополнительная информация раскрывается по нажатию */}
          <Collapse in={opened === index} mt="sm">
            <Grid gutter="xs">
              <Grid.Col span={4} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }}>
                <Text>Калории:</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  variant="unstyled"
                  value={dish.calories || undefined}
                  type="number"
                  classNames={{ input: 'underline-input' }}
                  style={{ width: '35px' }}
                  onChange={(e) => handleInputChange(index, 'calories', +e.target.value)}
                />
              </Grid.Col>
              <Grid.Col span={4} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end'  }}>
                <Text>БЖУ (г):</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Grid gutter="xs">
                  <TextInput
                    variant="unstyled"
                    value={dish.protein || undefined}
                    type="number"
                    classNames={{ input: 'underline-input' }}
                    style={{ width: '35px', marginRight: '10px' }}
                    onChange={(e) => handleInputChange(index, 'protein', +e.target.value)}
                  />
                  <TextInput
                    variant="unstyled"
                    value={dish.fat || undefined}
                    type="number"
                    classNames={{ input: 'underline-input' }}
                    style={{ width: '35px', marginRight: '10px' }}
                    onChange={(e) => handleInputChange(index, 'fat', +e.target.value)}
                  />
                  <TextInput
                    variant="unstyled"
                    value={dish.carbs || undefined}
                    type="number"
                    classNames={{ input: 'underline-input' }}
                    style={{ width: '35px', marginRight: '10px' }}
                    onChange={(e) => handleInputChange(index, 'carbs', +e.target.value)}
                  />
                </Grid>
              </Grid.Col>
              <Grid.Col span={4} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }}>
                <Text>Клетчатка (г):</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  variant="unstyled"
                  value={dish.fiber || undefined}
                  type="number"
                  classNames={{ input: 'underline-input' }}
                  style={{ width: '35px' }}
                  onChange={(e) => handleInputChange(index, 'fiber', +e.target.value)}
                />
              </Grid.Col>
              <Grid.Col span={6} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }}>
                <Button color='red' onClick={(_) => deleteDish(dish.id)}>Удалить</Button>
              </Grid.Col>
            </Grid>
          </Collapse>
        </Paper>
      ))}
      {dishes.length <= 6 && <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
          marginBottom: '120px', // перекрытие кнопкой сохранить
          height: '60px',
          backgroundColor: '#f1f3f5', // Слегка серый фон
          borderRadius: '8px',
          cursor: 'pointer',
        }}
        onClick={addNewDish}  // Обработчик добавления блюда
      >
        <Text style={{ fontSize: '24px', color: '#868e96' }}>+</Text>
      </Box> }
    <Box
      style={{
        position: 'fixed',
        bottom: '50px', // Над кнопкой "Сохранить"
        left: 0,
        right: 0,
        backgroundColor: '#f8f9fa',
        padding: '10px 20px',
        borderTop: '1px solid #dee2e6',
        textAlign: 'center',
      }}
    >
      <Group style={{ justifyContent: 'left', zIndex:1000 }}>  {/* Используем justifyContent */}
        <Text><b>Калории:</b> {Math.round(totals.total_calories)}</Text>
        <Text><b>БЖУ:</b> {Math.round(totals.total_protein)}г | {Math.round(totals.total_fats)}г | {Math.round(totals.total_carbohydrates)}г</Text>
        <Text><b>Клетчатка:</b> {Math.round(totals.total_fiber)}г</Text>
      </Group>
    </Box>

      {/* {!isModalOpen && */}
        <Button
          size="lg"
          fullWidth
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#2fa83b',
            color: '#fff',
            borderRadius: 0,
            zIndex: 100,
          }}
          onClick={() => setIsModalOpen(true)}
        >
          Сохранить
        </Button>
      {/* } */}
      <Modal style={{zIndex: 1000}} opened={isModalOpen} onClose={() => setIsModalOpen(false)} title="Выберите время приема пищи">
        <SegmentedControl
          value={mealType}
          onChange={setMealType}
          fullWidth
          data={[
            { label: '🍳 Завтрак', value: 'breakfast' },
            { label: '🍲 Обед', value: 'lunch' },
            { label: '🍽 Ужин', value: 'dinner' },
            { label: '🍏 Перекус', value: 'fast_meal' },
          ]}
          styles={{
            root: {
              display: 'flex',
              flexWrap: 'wrap',  // Элементы будут переноситься на новую строку при нехватке места
            },
            label: {
              flex: '1 1 25%',   // Элементы займут 25% ширины контейнера
              textAlign: 'center',
              padding: '8px',
              minWidth: '80px',  // Минимальная ширина для каждого элемента
            },
          }}
        />

        <Button
          fullWidth
          mt="md"
          style={{ backgroundColor: '#2fa83b', color: '#fff' }}
          disabled = {isSaveBtnDisabled}
          onClick={async () => saveDishesData(mealType)}
        >
          Сохранить
        </Button>
      </Modal>
    </div>
  );
};
